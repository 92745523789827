.game-name {
    width: 100%;
}

.game-name.MuiButton-root {
    text-transform: none;
    word-wrap: break-word;
}

#game-modal-title {
    background-color: #246eee;
    color: white;
}

.game-result-entries {
    text-align: center;
    text-transform: uppercase;
}

div.player-chips {
    margin: 0.2rem 0.1rem;
}