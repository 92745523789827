.sortBtn-container {
    margin: auto;
}

.header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 1rem;
    display: inline-block;
    border-bottom: solid 3px lightgray;
}