table {
    margin: auto;
    padding: 1rem;
    width: 50vw;
}

@media screen and (max-width: 600px) {
    table {
        width: 100vw;
    }
}

td {
    padding: 0.3rem 1rem;
}